export default ({ app, store }, inject) => {
  const ShowMessage = (content, color) => {
    store.commit('snackbar/showMessage', { content, color })
  }
  inject('notify', new ShowMessage())

  // inject('notify', {
  //   showMessage ({ content = '', color = '' }) {
  //     console.log('in notify')
  //    const notify =  store.commit('snackbar/showMessage', { content, color })
  //   }
  // })
}

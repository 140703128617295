export const defaultErrorMessages = {
  401: 'Not Authenticated: Login required',
  403: 'Not Authorized: Access not allowed',
  404: 'That resource could not be found.',
  422: 'Validation Error',
  500: 'Server Error: Please contact support.'
}

export const defaultGeneralErrorMessage = 'Error: Please try again.'

export default class ErrorMessages {
  /**
   * @param {object | null} errorMessages
   * @param {string | null} generalErrorMessage
   */
  constructor (errorMessages, generalErrorMessage) {
    this.errorMessages = errorMessages || defaultErrorMessages
    this.generalErrorMessage = generalErrorMessage || defaultGeneralErrorMessage
  }

  /**
   * Retrieve the error message for a particular status code.
   *
   * @param {number} errorStatus
   * @return {string} errorMessage
   */
  getErrorMessage (errorStatus) {
    if (errorStatus && this.errorMessages[errorStatus]) {
      return this.errorMessages[errorStatus]
    }

    return this.generalErrorMessage
  }
}

import Vue from 'vue'
import globalActions from '../api/GlobalActions.js'
import globalMutations from '../api/GlobalMutations.js'
import globalGetters from '../api/GlobalGetters.js'
import { resolveNotifications } from '@/api/ApiHelpers'

export const state = () => ({
  type: 'notifications',
  all: {},
  relations: [],
  effectedByYear: false,
  yearFields: [],
  reference: {},
  active: false,
  page: 1,
  modelLabels: {
    single: 'Notification',
    plural: 'Notifications'
  },
  aliasField: 'id',
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 50,
    sort: false,
    sortBy: 'name',
    page: 1,
    descending: true
  },
  total: 0,
  print: false,
  print_path: '',
  export: false,
  search: null,
  bound: {
    id: '',
    type: '',
    data: ''
  },
  filter: {
    type: '',
    data: ''
  },
  quickFilters: [],
  include: '',
  headers: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations,
  MERGE (state, resource) {
    const index = state.all[resource.id]
    if (index === -1) {
      resource.schema.id = resource.id
      Vue.set(state.all, resource.id, resource.schema)
    } else {
      resource.schema.id = resource.id
      Vue.set(state.all, resource.id, resource.schema)
    }
  },
  MERGEHEADERS (state, resource) {
    const index = state.headers
    if (index === -1) {
      Vue.set(state.headers, resource.field, resource)
    } else {
      const temp = { ...state.headers[resource.field], ...resource }
      Vue.set(state.headers, resource.field, temp)
    }
  }
}

// Actions ====================

export const actions = {
  ...globalActions,
  all ({ commit, state, dispatch }, payload) {
    payload.forEach((item) => {
      commit('MERGE', resolveNotifications(item))
      const model = item.data.type
      dispatch(`${model}/MERGENOTIFICATIONS`, item.data, {
        root: true
      })
    })
  },
  async list ({ commit, state, getters, dispatch }) {
    const id = this.$auth.user.data.id
    const results = await this.$repositories.userNotifications.subIndex(id)
    if (results) {
      if (Object.prototype.hasOwnProperty.call(results, 'error')) {
        return
      }
      dispatch('all', results.data)
    }
  },
  async update ({ commit, state, getters, dispatch }, { id, payload }) {
    const results = await this.$repositories[state.type].update({
      id,
      payload
    })
    if (Object.prototype.hasOwnProperty.call(results, 'error')) {
      return
    }
    dispatch('all', results.data)
  },
  MERGEHEADERS ({ commit, state }, payload) {
    const schema = Object.values(payload)
    schema.forEach((item) => {
      commit('MERGEHEADERS', item)
    })
  }
}

// Getters ====================

export const getters = {
  ...globalGetters
}
